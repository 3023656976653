import axios from 'axios';
import dompurify from 'dompurify';

function searchResultsHTML(brands) {
  return brands.map(brand => {
    return `
      <a href="/emailgrid/senderid/${brand._id}" class="search__result">
        <strong>${brand.primaryFrom}</strong>
      </a>
        `;
  }).join('');
}

function typeAhead(search) {
  if (!search) return;

  const searchInput = search.querySelector('input[name="search"]');
  const searchResults = search.querySelector('.search__results');
  searchInput.on('input', function() {
    // if there is no value, quit it!
    //console.log(this.value)
    if (!this.value) {
      searchResults.style.display = 'none';
      return; // stop!
    }

    // show the search results!
    searchResults.style.display = 'block';
    searchResults.innerHTML = '';

    //call the APi
    axios
      .get(`/api/v1/brandSearch?q=${this.value}`)
      .then(res => {
        if (res.data.length) {
          //console.log('there is data to show: ' +res.data)
          searchResults.innerHTML = dompurify.sanitize(searchResultsHTML(res.data));
        }
        })
      .catch(err => {
        console.error(err);
        });
      })
// handle keyboard inputs
  searchInput.on('keyup', (e) => {
    console.log(e)
    // if they aren't pressing up, down or enter, who cares!
    if (![38, 40, 13].includes(e.keyCode)) {
      return; // nah
    }
    const activeClass = 'search__result--active';
    const current = search.querySelector(`.${activeClass}`);
    const items = search.querySelectorAll('.search__result');
    let next;
    if (e.keyCode === 40 && current) {
      next = current.nextElementSibling || items[0];
    } else if (e.keyCode === 40) {
      next = items[0];
    } else if (e.keyCode === 38 && current) {
      next = current.previousElementSibling || items[items.length - 1]
    } else if (e.keyCode === 38) {
      next = items[items.length - 1];
    } else if (e.keyCode === 13 && current.href) {
      window.location = current.href;
      return;
    }
    if (current) {
      current.classList.remove(activeClass);
    }
    next.classList.add(activeClass);
  });
}

export default typeAhead;
